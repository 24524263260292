.healthBar {
  display: flex;
  gap: 0.25rem;
  flex-direction: column;
}

.healthBarTitle {
  color: #5f5f5f;
}

.healthChecksWrapper {
  height: 100%;
  justify-content: flex-start;
}

.fullHeight {
  height: 100%;
}

.timelineTop {
  height: 100%;
  width: 90%;
  border-radius: 6px 6px 0 0;
}

.timelineBottom {
  height: 100%;
  width: 90%;
  border-radius: 0 0 6px 6px;
}

.timelineWrapper {
  display: flex;
  gap: 0.25rem;
  height: 1.5rem;
  width: 100%;
}

.timelineWrapper:hover {
  cursor: pointer;
}

.timelineDescription {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1.5rem;
}

.timelineDescription span {
  color: #00000042;
}

.timelineDescription hr {
  background-color: #00000042;
  width: 75%;
  height: 1px;
  border: none;
}

.datatable-b {
  .datagrid {
    max-height: 90%;
  }

  .datatableTitle {
    width: 100%;
    font-size: 24px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .link {
      text-decoration: none;
      color: var(--green-50);
      font-size: 16px;
      font-weight: 400;
      border: 1px solid var(--green-50);
      padding: 5px;
      border-radius: 5px;
      cursor: pointer;
    }
  }

  .cellWithImg {
    display: flex;
    align-items: center;

    .cellImg {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      object-fit: cover;
      margin-right: 20px;
    }
  }

  .cellWithStatus {
    padding: 5px;
    border-radius: 5px;

    &.active {
      background-color: rgba(0, 128, 0, 0.05);
      color: green;
    }
    &.pending {
      background-color: rgba(255, 217, 0, 0.05);
      color: goldenrod;
    }
    &.passive {
      background-color: rgba(255, 0, 0, 0.05);
      color: crimson;
    }
  }

  .cellAction {
    display: flex;
    align-items: center;
    gap: 15px;

    .viewButton {
      padding: 2px 5px;
      border-radius: 5px;
      color: darkblue;
      border: 1px dotted rgba(0, 0, 139, 0.596);
      cursor: pointer;
    }

    .testButton {
      padding: 2px 5px;
      border-radius: 5px;
      color: crimson;
      border: 1px dotted rgba(220, 20, 60, 0.6);
      cursor: pointer;
    }
  }
}
