.role-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: calc(100% - 50px);
}

.role {
  width: auto !important;
  margin: 1rem;
  background-color: #fff !important;
  padding: 2rem 3.25rem;
  border-right: 4px solid #00aa18;
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  border-radius: 10px !important;
}

.case-title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
