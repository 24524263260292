.kyb-detail {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .kyb-details:hover {
    cursor: pointer;
  }

  .kyb-file-loading {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: center;
    justify-content: center;
    margin-top: 3rem;
  }

  .kyb-files-drag {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .kyb-file-list-wrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .kyb-file-list {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .kyb-files {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .kyb-file {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .kyb-file-description {
      display: flex;
      align-items: center;
      gap: 0.25rem;
    }

    .kyb-file-icons {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
  }

  .kyb-subdetail {
    display: grid;
    grid-template-columns: 25% 75%;
  }

  .kyb-subgrid {
    gap: 0.5rem;
    display: flex;
    flex-direction: column;
    overflow: auto;
  }

  .kyb-titles {
    font-weight: 500;
    font-size: 1.1rem;
    margin-bottom: 1rem;
  }

  .kyb-detail-head {
    display: grid;
    grid-template-columns: 80% 20%;
  }

  .kyb-info {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    .kyb-info-section-head {
      display: grid;
      grid-template-columns: repeat(2, 1fr);

      .kyb-info-section-1 {
        display: flex;
        gap: 0.75rem;
        align-items: center;
      }
    }

    .kyb-info-section-2 {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 0.5rem;
      align-items: center;
    }

    .kyb-info-section-3 {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column: 1 / 4;
      gap: 0.5rem;
      align-items: center;
    }

    .kyb-info-section-4 {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 0.5rem;
      align-items: center;
    }

    .kyb-info-section-span {
      grid-column: 1 / 4;
    }
  }

  .details-sub {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;

    .risk-level {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .risk-level-top {
        display: flex;
        justify-content: space-between;
      }
    }

    .status-level {
      display: flex;
      flex-direction: column;
      gap: 2.75rem;

      .status-level-top {
        display: flex;
        justify-content: space-between;
      }
    }
  }

  .details-sub2 {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .risk-level {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .risk-level-top {
        display: flex;
        justify-content: space-between;
      }
    }

    .status-level {
      display: flex;
      flex-direction: column;
      gap: 2.75rem;

      .status-level-top {
        display: flex;
        justify-content: space-between;
      }
    }
  }

  .kyb-detail-timeline {
    height: fit-content;

    .timeline-wrapper {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      margin-top: 1.5rem;
      max-height: 55rem;
      overflow-y: auto;
      padding-right: 0.5rem;
      overflow-x: hidden;
    }

    .timeline-card {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
  }

  .kyb-detail-documents {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    .kyb-documents-top {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }

  .kyb-detail-aml {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  .kyb-checks-flex {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .kyb-close {
    margin-top: 1rem;

    .kyb-check-status {
      display: flex;
      gap: 1rem;
      align-items: center;
    }
  }

  .kyb-detail-shareholders {
    display: flex;
    flex-direction: column;
    overflow: auto;

    .kyb-detail-shareholders-top {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }
}

.kyb-modal {
  position: absolute;
  min-width: 35rem;
  top: 25%;
  left: 27%;
  background-color: #fcfffd;
  padding: 1.5rem;
  border-radius: 1.5rem;

  .kyb-modal-buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.notifications-row {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.reports-row {
  display: flex;
  gap: 1rem;
  align-items: center;
  margin-left: auto;
}

.kyb-outdated {
  margin-top: 2rem;
  margin-bottom: 2rem;

  .kyb-outdated-title {
    color: var(--gray-70);
    font-size: 1.2rem;
    font-weight: 500;
  }

  hr {
    margin-top: 5rem;
  }
}

.kyb-card {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  .card-top {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }

  .card-box {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .card-titles {
    display: flex;
    flex-direction: column;
    margin-bottom: 0.5rem;
  }
}

.accordion-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.accordion-status {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.accordion-status-icon {
  width: fit-content;
}

.kyb-form {
  display: flex;
  flex-direction: row;

  .flex-1 {
    flex: 1;
    width: 24%;
  }
}

.kyb-buttons {
  margin-top: 1rem;
}

.persons-table {
  display: flex;
  flex-direction: column;
  width: auto !important;
  margin: 0.5rem;
  background-color: #fff !important;
  padding: 1.5rem;
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  border-radius: 10px !important;
  max-height: 25rem;
  overflow: auto;
}

.kyb-country-selector {
  width: 12rem;
}
