.checkbox-input-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  .checkbox-input-row {
    display: flex;
    flex-direction: column;
  }
}
