.download-report {
  display: flex;
  gap: .5rem;
  align-items: center;
  padding: 0.5rem 1rem;
  border-radius: var(--radius-xs);
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  font-size: .7rem;

  .download-icon {
    height: 1.5rem;
    width: 1.5rem;
  }
}