.chat_container {
  display: flex;
  flex-direction: column;
  padding: 10px;
  background-color: #f5f5f5;
  border-radius: 8px;
  max-height: 90vh;
  overflow-y: auto;
}

.reportDescription {
  font-weight: bold;
}
.report_title_separator {
  margin-top: 20px;
  margin-bottom: 10px;
  border-bottom: 1px solid #e0e0e0;
}

/** Report item */

.reportItem__titleSection {
  margin-top: 15px;
  margin-bottom: 15px;
}

/** TypingText Component */

.TypingText__outerContainer {
  padding-top: 10px;
  padding-bottom: 10px;
}
