.search-bar {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  flex-wrap: wrap;
}

.search-bar-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.5rem;
  align-items: center;
}

.sub-item {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.kyb-manual {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.kyb-manual-result {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.kyb-manual-result-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
}

.kyb-manual-button {
  margin-left: auto;
}

.forceWidth {
  max-width: 30vw;
}