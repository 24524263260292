.consumptions-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: calc(100% - 50px);
}

.consumptions {
  width: auto !important;
  margin: 1rem;
  background-color: #fff !important;
  padding: 2rem 3rem;
  border-right: 4px solid #00aa18;
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  border-radius: 10px !important;
  height: 75vh;
}

.title-consumptions {
  font-size: 24px;
  margin-bottom: 2.5rem;
  display: flex;
  justify-content: space-between;
}

.colors {
  color: #0ddd4e;
}

.consumptions-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  margin-bottom: 2rem;
}

.consumption-detail {
  background-color: #fff;
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  padding: 2.5rem;
  border-radius: 10px;
}

.consumption-detail-modal {
  position: absolute;
  width: 50%;
  top: 10% !important;
  left: 28% !important;
  overflow: scroll;
  display: block;
}

/* .details {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: space-between;
} */

.detail-title {
  display: flex;
  gap: 1rem;
  align-items: center;
  margin-bottom: 2rem !important;
}

.margin-bottom {
  margin-bottom: 1rem;
}

.margin-bottom-small {
  margin-bottom: 0.7rem;
}

.details-service {
  color: rgba(13, 222, 79, 0.899);
  font-size: larger;
  font-weight: 600;
}

.highcharts-figure,
.highcharts-data-table table {
  min-width: 320px;
  max-width: 100%;
  margin: 1em auto;
}

.highcharts-data-table table {
  font-family: Verdana, sans-serif;
  border-collapse: collapse;
  border: 1px solid #ebebeb;
  margin: 10px auto;
  text-align: center;
  width: 80%;
  max-width: 80%;
}
.highcharts-data-table caption {
  padding: 1em 0;
  font-size: 1.2em;
  color: #555;
}
.highcharts-data-table th {
  font-weight: 600;
  padding: 0.5em;
}
.highcharts-data-table td,
.highcharts-data-table th,
.highcharts-data-table caption {
  padding: 0.5em;
}
.highcharts-data-table thead tr,
.highcharts-data-table tr:nth-child(even) {
  background: #f8f8f8;
}
.highcharts-data-table tr:hover {
  background: #f1f7ff;
}

.own-style {
  border-radius: 10px;
}

.img-separator {
  margin-top: 16px;
  width: 100%;
}
