.preview-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: 2rem;

  .box-division {
    margin-bottom: 0.5rem;
  }

  .preview-title {
    font-size: larger;
    margin-bottom: 0.85rem;
  }
}

.preview-block {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  max-height: 32rem;
  overflow: auto;

  .preview-item {
    display: grid;
    grid-template-columns: 25% 75%;
    width: 100%;
    align-items: center;
  }

  .preview-subtitle {
    padding-right: 0.5rem;
  }

  .checklist-actions {
    padding-left: 0px;
    padding-inline-start: 0px;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
}

.preview-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;

  .preview-sub-buttons {
    display: flex;
    justify-content: space-between;
    gap: 0.5rem;
  }
}

.render-data-box {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  .render-data-items {
    display: flex;
    gap: 0.5rem;
    align-items: center;

    .render-data-items-row {
      display: flex;
      flex-direction: row;
      gap: 0.5rem;
    }
  }
}
