.diff-grid {
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 2rem;
}

.diff-container {
  display: flex;
  justify-content: flex-start;
  align-items: start;
  flex-direction: column;
  gap: 0.75rem;
}

.diff-accordion {
  margin-bottom: 20px;
  overflow: auto;
}

.diff-title-item {
  font-size: 1.4rem;
  margin-bottom: 1rem;
}

.diff-title {
  font-size: 1.9rem;
  color: var(--blue-70);
  margin-bottom: 1rem;
}
