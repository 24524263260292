.assign-users {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.assign-users-button {
  align-self: flex-end;
}

.state-container {
  display: grid;
  grid-template-columns: 4rem auto;
  align-items: center;
  gap: 0.5rem;
}

.state-edit-container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.MuiDataGrid-panel {
  position: absolute;
  margin: 0px !important;
  right: 10px !important;
  top: 210px !important;
  transform: none !important;
  inset: 210px 0px 0px auto !important;
}
