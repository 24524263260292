.user-details {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1.5rem;

  .user-details-top {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1.5rem;
  }

  .user-details-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1rem;
  }

  .user-details-bottom {
    display: grid;
    grid-template-columns: 15.5rem auto;
    gap: 1.5rem;
  }

  .user-tablist {
    &.MuiTabs-root .MuiTabs-flexContainer {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
    }
  }

  .MuiChip-root {
    justify-content: flex-start;
    width: 100%;
  }

  .MuiButtonBase-root.MuiTab-root {
    width: 100%;
    align-items: flex-start;
  }

  .user-info-bottom {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    gap: 1rem;
    width: 100%;
  }

  .user-details-states {
    padding-left: 1rem;
    display: grid;
    gap: 1rem;
  }

  .user-details-grid {
    display: grid;
    grid-template-columns: 70% 30%;
  }

  .user-info {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.5rem;
  }

  .user-info-3 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0.5rem;
  }

  .user-column-expand-2 {
    grid-column: 1 / 3;
  }

  .user-column-expand-3 {
    grid-column: 1 / 4;
  }

  .user-info-box {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    height: 100%;
    border: 1px dashed #1c282026;
  }

  .user-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
  }

  .user-id {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .user-uid {
    display: flex;
    align-items: center;
    gap: 0.25rem;
  }

  .user-box {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }

  .user-box-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.25rem;
  }

  .user-payment-details {
    grid-column: 1 / 3;
    display: flex;
    flex-direction: column;
    gap: 0.55rem;
  }

  .chip-origin {
    color: var(--calm-dark);
    background-color: var(--calm-light);
    width: fit-content;
  }

  .chip-destination {
    color: var(--alert-dark);
    background-color: var(--alert-light);
    width: fit-content;
  }
}
